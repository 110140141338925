import { range } from "lodash"
import {
  IconExplodingHead,
  IconFaceWithTongue,
  IconGrinningFace, IconPersonShrugging,
  IconPileOfPoo, IconSleepingFace,
  IconSmilingFace, IconTiredFace,
  IconZanyFace
} from 'assets/images'
import i18n from "../i18n/i18n";

export const specieses = [
  'arabica',
  'robusta',
  'liberica',
  'blend',
  'unknown'
]

export const sampleTypes = [
  'offer',
  'pre_shipment',
  'landed',
  'type',
  'stock_lot',
  'evaluation',
  'auction',
  'pre_auction',
  'spot',
  'other'
]

export const statuses = [
  'approved',
  'rejected'
]

export const grades = [
  'premium',
  'speciality',
  'exchange',
  'standard',
  'off_grade'
]

export const colors = [
  'blue_green',
  'bluish_green',
  'green',
  'greenish',
  'yellow_green',
  'pale_yellow',
  'yellowish',
  'brownish'
]

export const counterpartyTypes = [
  "trader",
  "logistic_team",
  "client",
  "origin_lab",
  "courrier",
  "warehouse"
]

export const fulfillmentTypes = [
  "local",
  "third_party"
]

export const fulfillmentStatus = [
  "unfulfilled",
  "requested",
  "fulfilled",
  "cancelled"
]

export const sortSampleShipments = [
  {key: 'shipment_number', sort: 'default', label: 'shipment.sort.shipment'},
  {key: 'customer', sort: 'default', label: 'shipment.sort.customer'},
  {key: 'of_samples', sort: 'default', label: 'shipment.sort.of_samples'},
  {key: 'created_at', sort: 'default', label: 'shipment.sort.created_at'},
  {key: 'fulfillment', sort: 'default', label: 'shipment.sort.type'},
  {key: 'sample_location', sort: 'default', label: 'shipment.sort.sample_location'},
  {key: 'fulfillment_status', sort: 'default', label: 'shipment.sort.fulfillment_status'},
  {key: 'country', sort: 'default', label: 'shipment.sort.country'},
]

export const sortSampleOrders = [
  {key: 'shipment_number', sort: 'default', label: 'order.sort.shipment'},
  {key: 'tastify_order_number', sort: 'default', label: 'order.sort.sample_order'},
  {key: 'customer', sort: 'default', label: 'order.sort.customer'},
  {key: 'of_shipment', sort: 'default', label: 'order.sort.of_shipment'},
  {key: 'created_at', sort: 'default', label: 'order.sort.created_at'},
  {key: 'sample_transaction_items_third_party_email', sort: 'default', label: 'order.sort.type'},
  {key: 'sample_transaction_items_sample_location', sort: 'default', label: 'order.sort.sample_location'},
  {key: 'fulfillment_status', sort: 'default', label: 'order.sort.fulfillment_status'},
  {key: 'country', sort: 'default', label: 'order.sort.country'}
]

export const filterSampleShipments = [
  { key: 'third_party_email_blank', group: 'type', items: ['third_party', 'local'], value: '' },
  { key: 'fulfillment_status', group: 'fulfillment_status', items: ['fulfilled', 'unfulfilled', 'cancelled'], value: '' },
  { key: 'created_at', group: 'created_at', items: [], value: '' },
  { key: 'customer', group: 'customer', items: [], value: '' },
  { key: 'country', group: 'country', items: [], value: '' },
  { key: 'purchase_contract_reference', group: 'purchase_contract_reference', items: [], value: '' },
  { key: 'sales_contract_reference', group: 'sales_contract_reference', items: [], value: '' },
]

export const filterSampleOrders = [
  { key: 'fulfillment_status', group: 'fulfillment_status', items: ['fulfilled', 'unfulfilled', 'cancelled'], value: '' },
  { key: 'customer', group: 'customer', items: [], value: '' },
  { key: 'country', group: 'country', items: [], value: '' },
  { key: 'purchase_contract_reference', group: 'purchase_contract_reference', items: [], value: '' },
  { key: 'sales_contract_reference', group: 'sales_contract_reference', items: [], value: '' },
]

export const defects = [
  { id: "full_black", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "full_sour", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "fungus_damage", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "foreign_matter", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "dried_cherry_pods", min: null, max: 1, equivalence: 1, type: "primary" },
  { id: "severe_insect_damage", min: null, max: 5, equivalence: 1, type: "primary" },
  { id: "partial_black", min: null, max: 3, equivalence: 1, type: "secondary" },
  { id: "partial_sour", min: null, max: 3, equivalence: 1, type: "secondary" },
  { id: "slight_insect_damage", min: null, max: 10, equivalence: 2, type: "secondary" },
  { id: "broken_chipped_cut", min: 2, max: 5, equivalence: 1, type: "secondary" },
  { id: "immature_bean", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "withered_bean", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "shell", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "floater", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "parchment", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "hull_husk", min: null, max: 5, equivalence: 1, type: "secondary" },
  { id: "quakers", min: null, max: 5, equivalence: 1, type: "secondary" }
]

export const printLabelSize = [
  {key: 'letter', width: '816', height: '1056', label: 'paper.letter', size: '279mm 215mm'},
  {key: 'dk', width: '253', height: '231', label: 'paper.dk', size: '67mm 61mm'},
  {key: 'dymo', width: '204', height: '382', label: 'paper.dymo', size: '54mm 101mm'},
  {key: 'sucafina_na', width: '200', height: '100', label: 'paper.sucafina_na', size: '200mm 100mm'},
]

export const courierNames = [
  'Aramark',
  'DHL',
  'EMS',
  'FedEx',
  'USPS',
  'AUSPOST',
  'UC',
  'SF Express',
]

export const defectKeys = ['primary', 'secondary']

export const scaSpecialtyDefects = defects

export const descriptorSetDefault = 'arabica'
export const descriptorSets = [
  { value: 'tastify_arabica', label: 'sample.tastifyArabica' },
  { value: 'sca_arabica', label: 'sample.scaArabica' }
]

export const cuppingProtocols = [
  'arabica',
  'robusta',
  'cup_of_excellence',
  'sca_affective',
  'sca_descriptive',
  'sca',
  'sucafina_tasting',
  'commercial',
  'soluble',
	'simplified_common'
]

export const dateCategory = [
  "created_date",
  "cupping_date",
  "green_grading_date",
  "shipment_date"
]

export const listYears = range((new Date()).getFullYear(), 2010)

export const defaultTotalScoreCuppingProtocols = [
  { key: 'arabica', totalScore: 165 },
  { key: 'robusta', totalScore: 160 },
  { key: 'cup_of_excellence', totalScore: 156 },
  { key: 'sca_affective', totalScore: 119 },
  { key: 'sca', totalScore: 119 },
  { key: 'sucafina_tasting', totalScore: 0 },
  { key: 'commercial', totalScore: 4 },
  { key: 'soluble', totalScore: 0 },
  { key: 'simplified_common', totalScore: 0 }
]

export const sampleIdStructures = [
  { value: 'number', label: 'cuppingSession.number' },
  { value: 'three_digits', label: 'cuppingSession.digit' },
  { value: 'letter', label: 'cuppingSession.letter' }
]

export const greenGradingTypes = [
  'sca_specialty',
  'gca',
  'brazil',
  'peru',
  'colombia',
  'vietnam_robusta',
  'nicaragua',
  'png',
  'drugar',
  'honduras'
]

export const cuppingSessionFilters = [
  { key: '', label: 'cuppingSession.noFilter' },
  { key: 'incomplete_sessions', label: 'cuppingSession.incompleteSessions' },
  { key: 'upcoming_sessions', label: 'cuppingSession.upcomingSessions' },
  { key: 'completed_sessions', label: 'cuppingSession.completedSessions' }
]

export const coffeeTypes = [
  'cherry',
  'parchment',
  'green_beans',
  'roasted_beans',
  'soluble',
  'others'
]

export const simplifiedCommonSelections = [
	'Phenol',
	'Very High',
	'High',
	'Medium/High',
	'Medium',
	'Medium/Low',
	'Mild/High',
	'Mild',
	'Mild/Low',
	'Low/Mild',
	'Low',
]

export const simplifiedCommonExpectations = [
	'Above',
	'Just Above',
	'Meets',
	'Just Meets',
	'Just Below',
	'Below',
	'Poor',
	'Needs to be Green Graded'
]

export const simplifiedCommonSampleResults = [
	'Approved',
	'Pending',
	'Pending Trader',
	'Pending Client',
	'Pending Green Grading',
	'Claim',
	'Rejected',
	'No Action Needed'
]

export const cuppingSessionSorts = {
  id: false,
  owner: false,
  time: false,
  description: false,
  location: false
}

export const sucafinaTastingTable = [
    {key: 'tastingScore', translation: 'descriptor.tasting'},
]

export const scaScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragrance'},
  {abbr: 'AR', key: 'aroma', translation: 'descriptor.aroma'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'MF', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'NU', key: 'nonUniformLength', translation: 'descriptor.nonUniformCups'},
  {abbr: 'DC', key: 'defectiveLength', translation: 'descriptor.defectiveCups'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const scaDescriptiveScoreTable = [
  {abbr: 'FA', key: 'fragranceIntensity', translation: 'descriptor.fragrance'},
  {abbr: 'AR', key: 'aromaIntensity', translation: 'descriptor.aroma'},
  {abbr: 'FL', key: 'flavorIntensity', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertasteIntensity', translation: 'descriptor.aftertaste'},
  {abbr: 'AC', key: 'acidityIntensity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessIntensity', translation: 'descriptor.sweetness'},
  {abbr: 'MF', key: 'mouthfeelIntensity', translation: 'descriptor.mouthfeel'},
]

export const arabicaScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragranceOrAroma'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'BD', key: 'body', translation: 'descriptor.body'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'BA', key: 'balance', translation: 'descriptor.balance'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'UN', key: 'totalUniformity', translation: 'descriptor.uniformity'},
  {abbr: 'CL', key: 'totalCleanCup', translation: 'descriptor.cleanCup'},
  {abbr: 'SW', key: 'totalSweetness', translation: 'descriptor.sweetness'},
  {abbr: 'DE', key: 'defects', translation: 'descriptor.defects'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const robustaScoreTable = [
  {abbr: 'FA', key: 'fragrance', translation: 'descriptor.fragranceOrAroma'},
  {abbr: 'SA', key: 'salt', translation: 'descriptor.saltOrAcid'},
  {abbr: 'BS', key: 'bittersweet', translation: 'descriptor.bitterOrSweet'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'MF', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'BA', key: 'balance', translation: 'descriptor.balance'},
  {abbr: 'UN', key: 'totalUniformity', translation: 'descriptor.uniformity'},
  {abbr: 'CL', key: 'totalCleanCup', translation: 'descriptor.cleanCup'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'DE', key: 'defects', translation: 'descriptor.defects'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const coeScoreTable = [
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'MF', key: 'mouthfeel', translation: 'descriptor.mouthfeel'},
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'BA', key: 'balance', translation: 'descriptor.balance'},
  {abbr: 'CL', key: 'cleanCupScore', translation: 'descriptor.cleanCup'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'OV', key: 'overall', translation: 'descriptor.overall'},
  {abbr: 'DE', key: 'defects', translation: 'descriptor.defects'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const commercialScoreTable = [
  {abbr: 'FL', key: 'flavor', translation: 'descriptor.flavor'},
  {abbr: 'BD', key: 'body', translation: 'descriptor.body'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'SW', key: 'sweetnessScore', translation: 'descriptor.sweetness'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const solubleScoreTable = [
  {abbr: 'AR', key: 'aroma', translation: 'descriptor.aroma'},
  {abbr: 'AC', key: 'acidity', translation: 'descriptor.acidity'},
  {abbr: 'BI', key: 'bitterness', translation: 'descriptor.bitterness'},
  {abbr: 'BD', key: 'body', translation: 'descriptor.body'},
  {abbr: 'AF', key: 'aftertaste', translation: 'descriptor.aftertaste'},
  {abbr: 'CO', key: 'consistency', translation: 'descriptor.consistency'},
  {abbr: 'TS', key: 'totalScore', translation: 'sampleScore.totalScore'},
]

export const simplifiedCommonScoreTable = [
	{abbr: 'TS', key: 'totalScore', translation: 'descriptor.score'},
	{abbr: 'AC', key: 'acidityNote', translation: 'descriptor.acidity'},
	{abbr: 'SW', key: 'sweetnessNote', translation: 'descriptor.sweetness'},
	{abbr: 'PAD', key: 'physicalAspectDescription', translation: 'descriptor.physicalAspectDescription'},
	{abbr: 'PAR', key: 'physicalAspectRate', translation: 'descriptor.physicalAspectRate'},
	{abbr: 'OV', key: 'overallNote', translation: 'descriptor.overallExpectation'},
	{abbr: 'RS', key: 'result', translation: 'descriptor.sampleResult'},
]

export const sampleStatuses = [
  {key: 'approved', label: 'sample.status.approve' },
  {key: 'rejected', label: 'sample.status.reject' }
]

export const roles = [
  {key: 'quality_control', label: 'options.role.qualityControl'},
  {key: 'barista', label: 'options.role.barista'},
  {key: 'trader', label: 'options.role.trader'},
  {key: 'homebrewer', label: 'options.role.homebrewer'},
  {key: 'coffee_buyer', label: 'options.role.coffeeBuyer'},
  {key: 'owner', label: 'options.role.owner'},
  {key: 'educator', label: 'options.role.educator'},
  {key: 'judges', label: 'options.role.judges'},
  {key: 'logistic', label: 'options.role.logistic'},
  {key: 'other', label: 'options.role.other'},
]

export const companyTypes = [
  {key: 'coffee_group', label: 'options.companyType.coffeeGroup'},
  {key: 'importer', label: 'options.companyType.importer'},
  {key: 'wholesale', label: 'options.companyType.wholesale'},
  {key: 'retail', label: 'options.companyType.retail'},
  {key: 'ngo', label: 'options.companyType.ngo'},
  {key: 'education', label: 'options.companyType.education'},
]

export const sucafinaTastingForm = [
  {value: 9, emoticon: IconSmilingFace},
  {value: 8, emoticon: IconExplodingHead},
  {value: 7, emoticon: IconFaceWithTongue},
  {value: 6, emoticon: IconZanyFace},
  {value: 5, emoticon: IconGrinningFace},
  {value: 4, emoticon: IconPersonShrugging},
  {value: 3, emoticon: IconSleepingFace},
  {value: 2, emoticon: IconTiredFace},
  {value: 1, emoticon: IconPileOfPoo}
]

export const menuList = [
  {
    value: 1,
    label: 'descriptor.extremelyLow',
    color: "#B12028"
  },
  {
    value: 2,
    label: 'descriptor.veryLow',
    color: "#BF3027"
  },
  {
    value: 3,
    label: 'descriptor.moderatelyLow',
    color: "#CD3E25"
  },
  {
    value: 4,
    label: 'descriptor.slightlyLow',
    color: "#DC4F22"
  },
  {
    value: 5,
    label: 'descriptor.neitherHighNorLow',
    color: "#FC6C17"
  },
  {
    value: 6,
    label: 'descriptor.slightlyHigh',
    color: "#D98428"
  },
  {
    value: 7,
    label: 'descriptor.moderatelyHigh',
    color: "#D98428"
  },
  {
    value: 8,
    label: 'descriptor.veryHigh',
    color: "#A4B040"
  },
  {
    value: 9,
    label: 'descriptor.extremelyHigh',
    color: "#7CD55A"
  },
]

export const scoreStep = 0.25
export const scoreCommercialStep = 1
export const maximumScore = 10
export const minimumScore = 5
export const maximumCupScore = 8
export const minimumCupScore = 0
export const maximumCommercialScore = 5
export const minimumCommercialScore = 1
export const roastLevels = [0, 25, 50, 75, 100]
export const defaultScores = range(5, 10.01, 0.25)
export const defaultScoreValue = 7.5
export const defaultScoreScaValue = 5
export const defaultScoreIntensity = 0
export const defaultScoreMarketing = 1
export const coeDefaultScores = range(0, 8.01, 0.25)
export const commercialDefaultScores = range(1, 5.01, 1)
export const defaultDefects = [0, 2, 4, 6, 8, 10, 12, 16, 20]
export const coeDefaultDefects = [0, 4, 8, 12, 16, 20, 24, 32, 40, 36, 48, 60]
export const cupOptions = [0,1,2,3,4,5]
export const intensityOptions = [0, 2, 4]
export const coeIntensityOptions = [0, 1, 2, 3]
export const coeFactor = 4
export const coeAdditionalScore = 36
export const solubleRoastLevels = [25, 75]
export const defaultScoreSolubleValue = 0
